import { version } from './version';

export const environment = {
  BASE_URL: 'https://dte.hmg.giss.com.br/PlatformaDTe',
  production: true,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  firebaseConfig: {
    apiKey: "AIzaSyCfkugEPINGr9sHKTa0anJq1HUkiNCRvbg",
    authDomain: "dte-id.firebaseapp.com",
    projectId: "dte-id",
    storageBucket: "dte-id.appspot.com",
    messagingSenderId: "619843953278",
    appId: "1:619843953278:web:7251e3a12840932a8b2e2f",
    measurementId: "G-ST0XXRQ7HP"
  },
  notificationApi: "https://dtealerta.eiconbrasil.com.br/pushSubscription/registrar",
  powerbi: '2',
  schema: 'pbi_dte_homol',
  groupId: '0078aca5-87f8-4c0b-a5d0-d8e0852b4090',
  analiseGeral: 'b47a476d-6494-4516-be5c-82f22a6c9800',
  analiseUsuarios: '',
  analiseComunicados: '138b912b-30af-4aff-b993-73eaba0c04cc',
  analiseProcurador: '',
  analiseEventos: 'aa7cd387-0b4d-44e6-be79-2b108c623c02'
};
